@font-face { font-family: Fancyfont; src: url('Fancyfont.ttf'); } 

.page {
    margin-top: 5px;
    background-color: #FFFEF2;
}

.column-desktop {
    width: 28%;
    color: black;
    margin: 13px;
    font-family: courier;
    font-size: small;
    outline-width: 2px;
    outline-color: black;
}
.column-mobile {
    color: black;
    margin: 5px;
    font-family: courier;
    font-size: small;
    outline-width: 2px;
    outline-color: black;
}

.header {
    color: black;
    font-family: 'Fancyfont';

}

.title {
    text-align: center;
    margin: 0px;
    padding: 0px;
    font-size: 40pt;
}
.row-desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    outline-width: 2px;
    outline-color: black;
}
.row-mobile {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    outline-width: 2px;
    outline-color: black;
}
.header-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: -10px;
    margin-bottom: -10px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    
}

.bar1 {
    background-color: black;
    height: 6px;
    margin-top: 0px;

}

.bar2 {
    background-color: black;
    height: 3px;
    margin-top: 0px;
}

.article-title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28pt;
    margin-top: 13px;
    margin-bottom: 5px;
    font-weight: bold;
}

.get-article {
    min-width: 25px;
    min-height: 25px;
    margin: 5px;
    background-color: black;
    color: aliceblue;
    border-radius: 15px;
}

.user-control {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: -10px;
}

.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.instruct-box-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer {
    color: black
}